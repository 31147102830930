import AusNZ from "../common/Icons/Countries/AUS-NZ"
import GCC from "../common/Icons/Countries/GCC"
import IND from "../common/Icons/Countries/IND"
import MULTI from "../common/Icons/Countries/MULTI"
import UK from "../common/Icons/Countries/UK"
import USA from "../common/Icons/Countries/USA"
import Canada from "../common/Icons/Countries/CANADA"
import React from "react"

export const exportsCountries = [
  {
    name: "United State Of America",
    symbol: "USA",
    flag: <USA />,
  },
  {
    name: "India",
    symbol: "IND",
    flag: <IND />,
  },
  {
    name: "Gulf Cooperation Council",
    symbol: "GCC",
    flag: <GCC />,
  },
  {
    name: "United Kingdom",
    symbol: "UK",
    flag: <UK />,
  },
  {
    name: "Australia & New Zealand",
    symbol: "AUS-NZ",
    flag: <AusNZ />,
  },
  { name: "Canada", symbol: "CA", flag: <Canada /> },
  {
    name: "Multi Country",
    symbol: "MULTI",
    flag: <MULTI />,
  },
]
