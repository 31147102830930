import React from "react"
import { useHistory } from "react-router-dom"
export default function QkCategory(props: any) {
  const params = useHistory()
  const placeholder = `${props.data.title} ${props.data.required ? "*" : ""}`
  let categoryNumber = props?.values?.category?.category_number
  let categoryValue = props?.values?.category?.category_name
  return (
    <div
      onClick={
        params?.location?.pathname.includes("edit") ? () => {} : () => {}
      }
      className={`${
        props.data.customStyle || ""
      } bg-white border border-bordergray rounded-lg shadow-sm pl-5 py-[0.6rem] text-left cursor-pointer`}
    >
      {categoryValue ? (
        <p className="leading-8 pl-3">{categoryNumber + " " + categoryValue}</p>
      ) : (
        <p className=" ml-1 text-textgraylight leading-8">{placeholder}</p>
      )}
    </div>
  )
}
